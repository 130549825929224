import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { ComplianceStatusDTO } from "../../../compliance/compliance-register/dto/compliance-status.dto";  // Adjusted DTO import

@Injectable({
  providedIn: 'root',
})
export class ComplianceStatusService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  list(
    sortField: string = 'title_en',
    sortOrder: string = 'asc',
    searchQuery?: string
  ): Observable<ComplianceStatusDTO[]> {
    let params = new HttpParams()
    .set('sort_by', sortField)
    .set('sort_order', sortOrder);

    if (searchQuery) {
      params = params.set('keyword', searchQuery);
    }

    return this.http.get<ComplianceStatusDTO[]>(this.apiUrl + '/compliance-statuses', {
      params,
    });
  }
}
